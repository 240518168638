import React, { Component } from 'react'
import {
  FacebookShareButton,
  TwitterShareButton,
  FacebookIcon,
  TwitterIcon,
  LineShareButton,
  LineIcon,
  PocketShareButton,
  PocketIcon,
} from 'react-share'
import urljoin from 'url-join'
import './style.scss'

class Share extends Component {
  render() {
    const { postNode, siteMetadata, mobile } = this.props
    const url = urljoin(siteMetadata.url, postNode.frontmatter.path)
    const iconSize = mobile ? 36 : 48

    return (
      <div className="share___links">
        <TwitterShareButton url={url} title={postNode.frontmatter.title}>
          <TwitterIcon size={iconSize} />
        </TwitterShareButton>
        <FacebookShareButton url={url} quote={postNode.frontmatter.description}>
          <FacebookIcon size={iconSize} />
        </FacebookShareButton>
        <LineShareButton url={url} quote={postNode.frontmatter.description}>
          <LineIcon size={iconSize} />
        </LineShareButton>
        <PocketShareButton url={url} >
          <PocketIcon size={iconSize} />
        </PocketShareButton>

      </div>
    )
  }
}

export default Share
